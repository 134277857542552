<template>
    <div id="appCapsule">
        <div class="section wallet-card-section">
            <div class="card">
                <div class="col-12 text-primary py-2">
                    HW Token Applications
                </div>
                <div class="table-responsive" style="padding-bottom: 100px">
                    <table class="table">
                        <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Ref</th>
                            <th scope="col">User</th>
                            <th scope="col">Status</th>
                            <th scope="col">Date created</th>
                            <th scope="col" class="text-end">Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-if="getTokens.length > 0">
                            <tr v-for="(application, idx) in getTokens" :key="idx">
                                <th scope="row">{{idx+1}}</th>
                                <td>{{application.id}}</td>
                                <td>
                                    <span v-if="users[idx]!==undefined">{{users[idx].data.name}}</span>
                                    <span v-else>{{application.data.user}}</span>
                                </td>
                                <td>
                                    <span class="badge badge-success" v-if="application.data.status === 'APPROVED'">
                                        {{application.data.status}}
                                    </span>
                                    <span class="badge badge-danger" v-else-if="application.data.status === 'DECLINED'">
                                        {{application.data.status}}
                                    </span>
                                    <span class="badge badge-warning" v-else-if="application.data.status === 'PENDING'">
                                        {{application.data.status}}
                                    </span>
                                    <span class="badge badge-primary" v-else>
                                        {{application.data.status}}
                                    </span>
                                </td>
                                <td>{{getReadableDatetime(application.data.createdAt)}}</td>
                                <td>
                                    <div class="dropdown">
                                        <a class="text-primary dropdown-toggle" href="javascript:void(0)" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                            <ion-icon name="list-outline" class="fw-20"></ion-icon>
                                        </a>
                                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                            <li>
                                                <a class="dropdown-item" href="javascript:void(0)" @click="active_application=application" data-bs-toggle="modal" data-bs-target="#viewCardApplication">
                                                    <ion-icon class="text-primary fw-20" name="eye-outline"></ion-icon> view
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" href="javascript:void(0)" @click="updateApplication(application,'APPROVED')">
                                                    <ion-icon  name="checkmark-done-outline" class="text-success fw-20"></ion-icon> approve
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" href="javascript:void(0)" @click="updateApplication(application,'PENDING')">
                                                    <ion-icon name="alert-circle-outline" class="text-warning fw-20"></ion-icon> pending
                                                </a>
                                            </li>
                                            <li>
                                                <a class="dropdown-item" href="javascript:void(0)" @click="updateApplication(application,'DECLINED')">
                                                    <ion-icon class="text-danger fw-20" name="ban-outline"></ion-icon> decline
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </td>
                            </tr>
                        </template>
                        <template v-else>
                            <tr>
                                <td class="text-center text-primary" colspan="8">
                                    <h5>Empty HW Token applications</h5>
                                </td>
                            </tr>
                        </template>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>

        <view-hw-token-application :application="active_application"/>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import methodsMixin from "../../utils/methodsMixin";
import viewHWTokenApplication from '../../components/admin/modals/viewHWTokenApplication';

export default {
    name: "HWApplications",
    data(){
        return {
            users: [],
            users_fetch_interval: null,
            active_application: {id: '', data: {}}
        }
    },
    computed: {
        ...mapGetters('HWToken', [
            'getTokens'
        ])
    },
    mixins: [methodsMixin],
    methods: {
        updateApplication(application, status) {
            this.$store.dispatch('notification/display_noti', {
                message: 'Confirm this application?',
                context: 'icon',
                buttons: [
                    {
                        name: 'Yes please',
                        callback: async () => {
                            this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons');
                            let response = await this.$store.dispatch('HWToken/updateApplication', {
                                token: application,
                                status
                            });
                            if (response.status) {
                                return this.$store.dispatch('notification/display_noti', {
                                    message: 'Hardware Token Application Confirmed',
                                    timeout: 1500
                                });
                            } else {
                                return this.$store.dispatch('notification/display_noti', {
                                    message: response.message,
                                });
                            }
                        }
                    },
                    {
                        name: 'Abort',
                        callback: () => this.$store.dispatch('notification/closeNoti', 'noti-ios-buttons')
                    }
                ]
            });
        },
        async fetchUsers() {
            if (this.getTokens.length > 0) {
                clearInterval(this.users_fetch_interval);
                try {
                    let promises = this.getTokens.map(application => this.$store.dispatch('user/getUser', application.data.user));
                    let responses = await Promise.all(promises);
                    this.users = responses.map(response => response.data);
                } catch (e) {
                    console.log(e)
                }
            } else {
                if(this.users_fetch_interval === null){
                    this.users_fetch_interval = setInterval(this.fetchUsers, 2000);
                }
            }
        },
    },
    components: {
        viewHwTokenApplication: viewHWTokenApplication
    },
    mounted() {
        this.$store.dispatch('HWToken/fetchAllTokens')
        this.fetchUsers()
    }
}
</script>

<style scoped>

</style>